import React, { useContext } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import "@styles/Privacidad.css";
import { Link } from "gatsby";
import { IpContext } from "@components/contexts/IpContext";
import SmallBanner from "@components/common/SmallBanner";

export default function Index() {
    const { warningIp } = useContext(IpContext);

    return (
        <>
            <Layout>
                <Seo title="Aviso legal" />
                <SmallBanner title="Aviso legal" filename="bgs/bgPrivacy" />

                <div className="container lg:px-0 py-20">
                    <div className="mb-10 privacidad">
                        <Title className="text-left">Información Legal LSSIYCE</Title>
                        <Text className="text-justify space-y-5">
                            <p>
                                De acuerdo con las obligaciones previstas en la Ley 34/2002 de
                                Servicios de la Sociedad de la Información y del Comercio
                                Electrónico (en adelante LSSIYCE),
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>
                                con CIF B09771155 y domicilio social en Plaça Gonzalez Isla, 10,
                                43895 L'Ampolla (Tarragona) y correo electrónico{" "}
                                <a href="mailto:info@iopos.academy" className="default-link">
                                    info@iopos.academy
                                </a>
                                , manifiesta que son de su propiedad:
                            </p>
                            <ul>
                                <li>- el dominio www.iopos.es</li>
                                <li>- el dominio www.iopos.academy</li>
                                <li>- el dominio www.igub2.net</li>
                                <li>
                                    - los perfiles y página en las siguientes:
                                    <ul className="space-y-1 ml-5 mt-2">
                                        <li>
                                            <strong>Facebook:</strong>{" "}
                                            <a
                                                href="https://www.facebook.com/iopos"
                                                className="default-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                https://www.facebook.com/iopos
                                            </a>
                                        </li>
                                        <li>
                                            <strong>Instagram:</strong>{" "}
                                            <a
                                                href="https://www.instagram.com/iopos.es"
                                                className="default-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                https://www.instagram.com/iopos.es
                                            </a>
                                        </li>
                                        <li>
                                            <strong>Linkedin:</strong>{" "}
                                            <a
                                                href="https://www.linkedin.com/company/iopos"
                                                className="default-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                https://www.linkedin.com/company/iopos
                                            </a>
                                        </li>
                                        <li>
                                            <strong>Youtube:</strong>{" "}
                                            <a
                                                href="https://www.youtube.com/channel/UCbjYROOdRtc_y1VEUeCiFBQ"
                                                className="default-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                https://www.youtube.com/channel/UCbjYROOdRtc_y1VEUeCiFBQ
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                Que{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>{" "}
                                está inscrita en el Registro Mercantil de Tarragona, en el Tomo
                                3301, Hoja 50, Folio T-58519.
                            </p>
                            <p>
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> no
                                puede asumir ninguna responsabilidad derivada del uso incorrecto o
                                ilícito de la información que aparezca en sus páginas web o
                                presencia online en las diversas redes sociales. Con los límites
                                establecidos en la ley,
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> no
                                asume ninguna responsabilidad por la falta de veracidad, integridad,
                                actualización y precisión de los datos o informaciones que se
                                contienen en sus páginas de Internet.
                            </p>
                            <p>
                                Los contenidos e información de las páginas de Internet y redes
                                sociales de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>
                                elaborados por profesionales debidamente cualificados para el
                                ejercicio de su profesión. Sin embargo, los contenidos e información
                                no vinculan a la susodicha, ni constituyen opiniones, consejos o
                                asesoramiento legal de ningún tipo, pues se trata meramente de un
                                servicio ofrecido con carácter informativo y divulgativo.
                            </p>
                            <p>
                                Las páginas de Internet y redes sociales de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>{" "}
                                pueden contener enlaces (links) a otras páginas de terceras. Por lo
                                tanto, ésta no puede asumir responsabilidades por el contenido que
                                pueda aparecer en páginas de terceros. Los textos, imágenes,
                                sonidos, animaciones, software y el resto de contenido incluidos en
                                este website son propiedad exclusiva de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> o
                                sus licenciantes. Cualquier acto de transmisión, distribución,
                                cesión, reproducción, almacenamiento o comunicación pública total o
                                parcial, debe contar con el consentimiento expreso de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>.
                            </p>
                            <p>
                                De la misma manera, se reserva el derecho a retirar el acceso al
                                portal sin necesidad de previo aviso a cualquier usuario que
                                contravenga lo dispuesto en el presente aviso legal.
                            </p>
                        </Text>
                    </div>

                    <div className="mb-10 aviso-legal">
                        <Title className="text-left">Redes Sociales</Title>
                        <Text className="text-justify space-y-5">
                            <p>
                                El usuario deberá disponer de su perfil en las redes sociales para
                                poder interactuar con{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>y{" "}
                                {""}
                                que acepte la relación con nuestra red social. Si así se ejecuta,{" "}
                                <strong>
                                    IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.
                                </strong>{" "}
                                tendrá acceso al perfil del usuario de acuerdo con los parámetros
                                estipulados por la propia red social y podrá interactuar con él
                                mediante el envío de mensajes, publicaciones, participación en
                                concursos y sorteos. Si el usuario no desea que{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>{" "}
                                pueda acceder a su perfil o interrelacionarse con él será necesario
                                que se desvincule del perfil dejando de seguir o eliminar como
                                amistad, seguidor o usuario a{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>.
                            </p>
                            <p>
                                Las actuaciones que realice{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>{" "}
                                podrán tener carácter informativo y/o publicitario de sus servicios
                                o productos, considerando que la red social es un medio
                                publicitario, bajo la responsabilidad de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> en
                                cuanto a las publicaciones y comunicaciones que se realicen por
                                estos medios online.
                            </p>
                            <p>
                                La participación de los usuarios en concursos, promociones o sorteos
                                implicará que el usuario acepta las bases legales de este concurso,
                                promoción o sorteo que serán publicados en la red social de
                                referencia en el momento oportuno y con su participación implicará
                                que el usuario ha aceptado las mismas.
                            </p>
                            <p>
                                Se recuerda a los usuarios que las publicaciones que realicen en las
                                redes sociales estarán bajo su responsabilidad y será necesario que
                                respeten la legislación y los derechos de terceros que puedan
                                afectar con especial relevancia a los derechos de propiedad
                                intelectual, de protección de datos y de la propia imagen de
                                terceros, así como las normas de las propias redes sociales que
                                consten en los siguientes enlaces:
                            </p>

                            <ul>
                                <li>
                                    Facebook:{" "}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="default-link"
                                        href="https://www.facebook.com/legal/terms"
                                    >
                                        https://www.facebook.com/legal/terms
                                    </a>
                                </li>
                                <li>
                                    Instagram:{" "}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="default-link"
                                        href="https://help.instagram.com"
                                    >
                                        https://help.instagram.com
                                    </a>
                                </li>
                                <li>
                                    LinkedIn:{" "}
                                    <a
                                        className="default-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.linkedin.com/legal/user-agreement"
                                    >
                                        https://www.linkedin.com/legal/user-agreement
                                    </a>
                                </li>
                                <li>
                                    Youtube:{" "}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="default-link"
                                        href="https://www.youtube.com/t/terms"
                                    >
                                        https://www.youtube.com/t/terms
                                    </a>
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 aviso-legal">
                        <Title className="text-left">Protección de datos personales</Title>
                        <Text className="text-justify space-y-5">
                            <p>
                                En virtud de la normativa de protección de datos de carácter
                                personal, por la presente autoriza a que los datos personales
                                facilitados son tratados bajo responsabilidad de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> y
                                domicilio en Plaça Gonzalez Isla, 10, 43895 L’Ampolla (Tarragona):
                            </p>
                            <ul className="space-y-5">
                                <li>
                                    - Formulario de Contacto: Sus datos serán tratados con la
                                    finalidad de gestionar su petición y poderle dar respuesta,
                                    siendo esta la causa que legitima el tratamiento. Sus datos
                                    serán conservados hasta que se resuelva la petición, y no serán
                                    transmitidos a terceros.
                                </li>
                                <li>
                                    - Si son facilitados en el formulario de compra, estos datos
                                    serán tratados con la finalidad de llevar la gestión y control
                                    de los clientes que adquieren los productos y servicios y la
                                    gestión de todo el procedimiento para el ejercicio de la
                                    actividad de la empresa, siendo la prestación de este servicio
                                    el motivo que nos legitima a tratar sus datos. Estos datos solo
                                    serán transmitidos cuando sean necesarios para la realización de
                                    la entrega del pedido y serán conservados siempre que sea
                                    necesario por la normativa de contratación y las obligaciones
                                    derivadas de ella.
                                </li>
                                <li>
                                    - Si son tratados en nuestras redes sociales, de acuerdo con lo
                                    detallado anteriormente, estos serán tratados con el fin de
                                    gestionar aquella información y datos obtenidos de la
                                    interactuación de los usuarios de estas redes con los
                                    perfiles/página de la entidad. Estos datos serán conservados
                                    siempre que se mantenga el interés legítimo que permitió su
                                    tratamiento.
                                </li>
                            </ul>
                            <p>
                                En cualquier caso, podrá indicar la revocación del consentimiento
                                dado, si ésta fue la base que legitimó el tratamiento o ejercitar
                                los derechos previstos:
                            </p>
                            <ul className="space-y-5">
                                <li>
                                    <strong>· De acceso:</strong> el interesado tiene derecho a
                                    saber si el responsable trata datos personales suyos y, si es
                                    así, tiene derecho a acceder a estos datos, así como a obtener
                                    información al respecto de los tratamientos realizados.
                                </li>
                                <li>
                                    <strong>· Rectificación:</strong> Está vinculado al carácter de
                                    inexactitud o incompleto de los datos. El interesado tiene
                                    derecho a rectificar sus datos personales inexactos y que se
                                    completen sus datos personales incompletos.
                                </li>
                                <li>
                                    <strong>· Supresión:</strong> El interesado tiene derecho a
                                    obtener la supresión de sus datos personales ("derecho al
                                    olvido"), cuando estos ya no son necesarios para la finalidad
                                    para la cual se recogieron, se revoca el consentimiento en el
                                    cual se basaba el tratamiento, el interesado se opone al
                                    tratamiento, los datos se han tratado ilícitamente, se tienen
                                    que suprimir para cumplir una obligación legal o se han obtenido
                                    en relación con la oferta de servicios de la sociedad de la
                                    información dirigida a menores.
                                </li>
                                <li>
                                    <strong>· Limitación del tratamiento:</strong> es un derecho de
                                    la persona interesada consistente en marcar sus datos de
                                    carácter personal conservados con la finalidad de limitar el
                                    tratamiento en el futuro. La limitación del tratamiento supone
                                    que, a petición de la persona interesada, sus datos personales
                                    indicados se dejen de tratar.
                                </li>
                                <li>
                                    <strong>· Oposición:</strong> el interesado tiene derecho a
                                    oponerse al tratamiento de sus datos cuando se base en algunos
                                    supuestos concretos y se invoque un motivo relacionado con su
                                    situación personal.
                                </li>
                                <li>
                                    <strong>· Portabilidad de los datos:</strong> el interesado
                                    tiene derecho a recibir sus datos personales facilitados al
                                    responsable del tratamiento en un formato estructurado, de uso
                                    común y de lectura mecánica, si se basa en el consentimiento o
                                    en un contrato o cuando el tratamiento se realiza por medios
                                    automatizados.
                                </li>
                                <li>
                                    <strong>
                                        · A no ser objeto de decisiones individuales automatizadas:
                                    </strong>{" "}
                                    el interesado tiene derecho a no ser objeto de una decisión
                                    basada en el tratamiento automatizado de sus datos, incluida la
                                    elaboración de perfiles que produzca efectos jurídicos en él o
                                    que le afecte negativamente.
                                </li>
                            </ul>
                            <p>
                                Estas peticiones se deberán realizar en Plaça Gonzalez Isla, 10,
                                43895 L’Ampolla (Tarragona) o en{" "}
                                <a href="mailto:info@iopos.academy" className="default-link">
                                    info@iopos.academy
                                </a>
                                .
                            </p>
                            <p>
                                Asimismo, también podrá presentar una reclamación ante la Agencia
                                Española de Protección de Datos.
                            </p>
                        </Text>
                    </div>

                    <div className={`mb-10 aviso-legal`}>
                        <Title className="text-left">Propiedad Industrial e Intelectual</Title>
                        <Text className="text-justify space-y-5">
                            <p>
                                Este sitio web es propiedad de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>.
                                Los derechos de propiedad intelectual y los derechos de explotación
                                y reproducción de este sitio web, sus páginas, su presencia en redes
                                sociales, pantallas, la información que contienen, su apariencia y
                                diseño, así como los enlaces ("hipervínculos") establecidos desde
                                ella a otras páginas web de cualquier centro adscrito u organismo
                                dependiente, son propiedad exclusiva de ésta salvo que expresamente
                                se estableciese otra cosa.
                            </p>
                            <p>
                                Todas las denominaciones, diseños y/o logotipos, así como cualquier
                                producto o servicio ofrecido y reflejado en este sitio web, son
                                marcas comerciales debidamente registradas por{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>{" "}
                                por sus subsidiarias y/o dominadas o por terceros.
                            </p>
                            <p>
                                Cualquier uso indebido del mismo por personas distintas de su
                                legítimo titular y sin el consentimiento expreso e inequívoco de
                                éste podrá ser denunciado y enjuiciado por todos los medios legales
                                existentes en el ordenamiento jurídico español y/o comunitario.
                            </p>
                            <p>
                                Los derechos de propiedad intelectual y marcas comerciales de
                                terceros se destacan convenientemente y deben ser respetados por
                                cualquier persona que acceda a esta página, no siendo responsable de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> el
                                uso que el usuario pueda llevar a cabo a este respecto, siendo la
                                única responsabilidad sobre su persona.
                            </p>
                            <p>
                                Sólo para uso personal y privado está permitido descargar los
                                contenidos, copiar o imprimir cualquier página de este sitio web y
                                redes sociales. Queda prohibido reproducir, transmitir, modificar o
                                eliminar la información, contenidos o advertencias de este sitio web
                                sin la autorización previa por escrito de{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>
                            </p>
                            <p>
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> no
                                transfiere a los usuarios la propiedad de su software. El usuario es
                                el propietario del medio en el que se graba el software.{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>{" "}
                                posee todos los derechos de propiedad industrial e intelectual,
                                incluido el software. Si el usuario transfiere software de este
                                sitio web a su terminal, no podrá diseccionar por su estudio y
                                descompilar, traducir la versión del código objeto original o su
                                idioma a otro código o idioma.
                            </p>
                            <p>
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong> no
                                transfiere a los usuarios la propiedad de su software. El usuario es
                                el propietario del medio en el que se graba el software.{" "}
                                <strong>IOPOS TECNOLOGIA APLICADA A LAS OPOSICIONES S.L.</strong>{" "}
                                posee todos los derechos de propiedad industrial e intelectual,
                                incluido el software. Si el usuario transfiere software de este
                                sitio web a su terminal, no podrá diseccionar por su estudio y
                                descompilar, traducir la versión del código objeto original o su
                                idioma a otro código o idioma.
                            </p>
                        </Text>
                    </div>

                    <div className="mb-10 aviso-legal">
                        <Title className="text-left">Ley aplicable y juridiscción competente</Title>
                        <Text className="text-justify">
                            <p>
                                Las presentes condiciones generales se rigen por la Legislación
                                española, siendo competentes los Juzgados y Tribunales españoles
                                para conocer de cuantas cuestiones se susciten sobre la
                                interpretación, aplicación y cumplimiento de las mismas. El usuario,
                                por virtud de su aceptación a las condiciones generales recogidas en
                                este aviso legal y siempre que esta sumisión sea posible, renuncia
                                expresamente a cualquier fuero que, por aplicación de la Ley de
                                Enjuiciamiento Civil vigente pudiera corresponderle.
                            </p>
                        </Text>
                    </div>
                </div>
            </Layout>
        </>
    );
}
